@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue/src/index.scss';
@import '~hopscotch/dist/css/hopscotch.css';
@import '~vue2-daterange-picker/dist/vue2-daterange-picker.css';

/* Page layout */
html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 80px;
    background-color: #f7f7f7;
}

body.kernel-iframe {
    overflow-y: hidden;
}

body.kernel-iframe nav,
body.kernel-iframe #menubar {
    display: none;
}

body.kernel-iframe #maincontent {
    margin-top: 0;
    padding: 0;
    overflow-x: hidden;
}

body.kernel-iframe.booking-new #cookie_display {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
}

body.kernel-iframe.booking-new #cookie_display p {
    color: #ffffff;
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
}

body.morewidth .container {
    width: 100%;
    max-width: 80%;
}

body.fullwidth .container {
    width: 100%;
    max-width: 100%;
}

body.fullwidth footer .container,
body.morewidth footer .container {
    width: calc(100% - 30px);
}

.sticky {
    position: sticky;
}

#height_change_listener {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border: 0;
    background: transparent;
}

body.staging #topbar {
    background: repeating-linear-gradient(-45deg, #ffff33, #ffff33 10px, #283844 10px, #283844 20px);
    padding-top: 0;
    padding-bottom: 0;
}

body.staging #topbar .container {
    background-color: #283844;
    padding: 4px 0px;
}

/* Cards */
@media (min-width: 576px) {
    body.search #results:not(.results-grid) .card {
        max-width: calc((100% / 2) - 30px);
        flex-basis: calc((100% / 2) - 60px);
    }
}

@media (min-width: 1200px) {
    body.search #results:not(.results-grid) .card {
        max-width: calc((100% / 3) - 30px);
        flex-basis: calc((100% / 3) - 60px);
    }
}

.card {
    border-radius: 0;
}

.card-img-top {
    border-radius: 0;
}

.card-header {
    background-color: #ffffff;
}

.card-img-top {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.card-img-top::after {
    content: "";
    display: block;
    padding-top: calc((100% / 3) * 2);
}

/* Tabs */
.card-header-tabs a.nav-link {
    border-radius: 0;
}

/* New Booking */
.steps-step p {
    margin-top: 10px;
}

.steps-row {
    display: table-row;
}

.steps {
    display: table;
    width: 100%;
    position: relative;
    table-layout: fixed;
}

.steps-step .btn-secondary.disabled {
    opacity: 1;
    color: #aaa;
    background-color: #fff;
    border-color: #ccc;
}

.steps-step .btn-secondary.disabled + p {
    color: #aaa;
}

.steps-step .btn-primary {
    color: #fff !important;
}

.steps-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
}

.steps-step {
    display: table-cell;
    text-align: center;
    position: relative;
    width: 100%;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

/* Colors */
.border-success::before,
.border-warning::before,
.border-danger::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
}

.border-success::before {
    border-left: 5px solid #28a745;
}

.border-warning {
    border-left: 5px solid #ffc107;
}

.border-danger {
    border-left: 5px solid #dc3545;
}

/* Alerts */
.alert {
    border-radius: 0;
}

.alert::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
}

.alert-info::before {
    border-left: 5px solid #17a2b8;
}

.alert-success::before {
    border-left: 5px solid #28a745;
}

.alert-warning::before {
    border-left: 5px solid #ffc107;
}

.alert-danger::before {
    border-left: 5px solid #dc3545;
}

/* Loader */
.loader-wrap {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    color: #FFFFFF;
    text-align: center;
}

.loader-wrap .spinner-border {
    position: fixed;
    top: calc(50% - 1rem);
}

/* Navigation */
#topbar .container {
    text-align: center;
}

@media (max-width: 767px) {
    #topbar .container {
        text-align: left;
    }
}

#navbar.fixed-top {
    top: 58px;
}

.navbar.bg-inverse {
    background-color: #283844 !important;
}

#topbar, #navbar {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

@media (max-width: 992px) {
    #navbar {
        padding-top: 0;
        padding-bottom: 0;
    }

    #navbar .nav-item {
        margin: 0.2rem 0;
    }
}

#navbar > .container {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

#navbar {
    background-color: #283844;
}

#navbar .dropdown-menu {
    margin-top: 0.25rem;
    border-top: none;
    border-radius: 0;
}

#toggleNavigation {
    position: absolute;
    right: 1rem;
    top: 0.55rem;
}

#navbar .nav-item {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

#navbar a.nav-link {
    color: #FDFDFD;
}

#navbar a.nav-link:hover {
    color: #CCCCCC;
}

#navbar .dropdown-menu a.nav-link {
    color: inherit;
}

#navbar .dropdown-menu a.nav-link:hover {
    color: #444444;
}

#navbar .nav-link {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

/* Main Content */
#maincontent {
    margin-top: 113px;
}

@media (max-width: 1200px) {
    #maincontent {
        max-width: 90%;
    }
}

@media (max-width: 992px) {
    #maincontent {
        margin-top: 84px;
        max-width: 100%;
    }
}

/* Chart boxes */
.chart-box .card-title {
    margin-bottom: 0;
}

.chart-box .chart {
    width: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.chart-box .chart:after {
    display: block;
}

.chart-box .chart:after {
    text-align: center;
    content: 'Loading...';
    padding-bottom: calc(56.25% - 20px);
}

.chart-box .chart > div {
    position: absolute !important;
    left: 0;
    right: 0;
}

/* Calendar */
.fc-event {
    cursor: pointer;
}

.fc-content {
    text-overflow: ellipsis;
    color: #ffffff;
}

.fc table {
    font-size: 0.95em !important;
}

.fc-toolbar h2 {
    margin: 0;
    font-size: 1.4rem !important;
    margin-top: 0.5rem !important;
}


/* Typography */
.h1, h1 {
    font-size: 2rem;
    color: #222222;
    padding-bottom: 5px;
    margin-bottom: 32px;
    margin-top: 15px;
}

.text-black {
    color: #000000;
}

.bg-white {
    background-color: #ffffff;
}

.bg-highlight {
    background-color: #F5F5DC;
    border-color: #F5F5DC !important;
}

.field-optional::after {
    content: '- Optional';
    font-style: italic;
    margin-left: 6px;
    font-size: 80%;
    font-weight: 400;
    color: #6c757d;
}

/* Modals */
.modal-xl {
    max-width: 100%;
    width: calc(100% - 40px);
}

.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

/* Tables */
.table.table-simple {
    margin-bottom: 0;
}

.table.table-simple tr td {
    border: none;
}

.table tr th {
    text-align: center;
}

.table th,
.table tr:first-of-type td {
    border-top: none;
}

.tablesorter .tablesorter-header {
    background-color: transparent !important;
}

.table td,
.table th {
    vertical-align: middle;
}

/* Button groups */
.btn-group .btn-secondary.active {
    background-color: #FFFFFF;
    border-color: #007bff;
}

/* Footer */
.kb-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 51px;
    width: 100%;
}

.kb-footer .container {
    border-top: 1px solid #dddddd;
    line-height: 50px;
    font-size: 0.8rem;
}

@media (max-width: 992px) {
    .kb-footer .container {
        max-width: 100%;
    }
}

.kb-footer a {
    color: rgb(99, 108, 114);
}

/* Bootstrap Overrides */
.btn-secondary {
    background-color: #FFFFFF;
    border-color: #CCCCCC;
    color: #292B2C;
}

.btn-secondary:not(.disabled):hover,
.btn-secondary:not(.disabled):active,
.btn-secondary:not(.disabled):focus {
    background-color: #E6E6E6 !important;
    border-color: #ADADAD !important;
    color: #292B2C !important;
}

/* Tour */
.hopscotch-bubble {
    border: 5px solid #666666 !important;
    font-family: inherit !important;
    font-size: inherit !important;
}

.hopscotch-bubble h3 {
    font-family: inherit !important;
    color: rgb(33, 37, 41) !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    margin-bottom: 0.75rem !important;
}

.hopscotch-content {
    font-family: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
}

.hopscotch-bubble-number {
    background: #2daae1 !important;
    padding: 0 !important;
    margin-right: 10px !important;
    border-radius: 50%;
}

.right .hopscotch-bubble-arrow-border {
    border-left: 17px solid #666666 !important;
}

.left .hopscotch-bubble-arrow-border {
    border-right: 17px solid #666666 !important;
}

.up .hopscotch-bubble-arrow-border {
    border-bottom: 17px solid #666666 !important;
}

.down .hopscotch-bubble-arrow-border {
    border-top: 17px solid #666666 !important;
}

.hopscotch-next {
    background-image: none !important;
    background-color: #007bff !important;
    border: none !important;
    padding: .375rem .75rem !important;
    font-size: 1rem !important;
    height: auto !important;
    font-weight: 400 !important;
    text-shadow: none !important;
    transition: background-color .15s ease-in-out;
    border-radius: 4px !important;
}

.hopscotch-next:hover {
    background-image: none !important;
    background-color: #0069d9 !important;
}

/* Availability Grid */
.day {
    width: 35.6px;
    position: relative;
    border: 1px solid #FFF;
}

.closed-start.closed-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(198, 198, 198, .2) 50%, rgba(198, 198, 198, .2) 50%);
}

.closed-start.available-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(198, 198, 198, .2) 50%, rgba(164, 234, 93, .7) 50%);
}

.closed-start.unavailable-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(198, 198, 198, .2) 50%, rgba(234, 93, 93, .5) 50%);
}

.closed-start.low-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(198, 198, 198, .2) 50%, rgba(234, 225, 93, .5) 50%);
}

.available-start.closed-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(164, 234, 93, .7) 50%, rgba(198, 198, 198, .2) 50%);
}

.available-start.available-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(164, 234, 93, .7) 50%, rgba(164, 234, 93, .7) 50%);
}

.available-start.unavailable-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(164, 234, 93, .7) 50%, rgba(234, 93, 93, .5) 50%);
}

.available-start.low-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(164, 234, 93, .7) 50%, rgba(234, 225, 93, .5) 50%);
}

.unavailable-start.closed-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 93, 93, .5) 50%, rgba(198, 198, 198, .2) 50%);
}

.unavailable-start.available-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 93, 93, .5) 50%, rgba(164, 234, 93, .7) 50%);
}

.unavailable-start.unavailable-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 93, 93, .5) 50%, rgba(234, 93, 93, .5) 50%);
}

.unavailable-start.low-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 93, 93, .5) 50%, rgba(234, 225, 93, .5) 50%);
}

.low-start.closed-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 225, 93, .5) 50%, rgba(198, 198, 198, .2) 50%);
}

.low-start.available-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 225, 93, .5) 50%, rgba(164, 234, 93, .7) 50%);
}

.low-start.unavailable-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 225, 93, .5) 50%, rgba(234, 93, 93, .5) 50%);
}

.low-start.low-end:not(.in-range) {
    background-image: linear-gradient(to bottom right, rgba(234, 225, 93, .5) 50%, rgba(234, 225, 93, .5) 50%);
}

/* Onboarding modal */
#carouselOnboarding .carousel-control-prev,
#carouselOnboarding .carousel-control-next {
    color: #444444;
}

#carouselOnboarding .carousel-item {
    text-align: center;
}

#carouselOnboarding .carousel-caption {
    position: initial;
    color: #444444;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

#carouselOnboarding .carousel-indicators li {
    background-color: rgba(68, 68, 68, .5);
}

#carouselOnboarding .carousel-indicators li.active {
    background-color: rgba(68, 68, 68, 1);
}

/* Toggle buttons */
.btn-group .btn.active {
    color: #292B2C !important;
    background-color: #FFFFFF !important;
    border-color: #0062cc !important;
    border-width: 3px;
}

@media print {

    body {
        background-color: transparent;
    }

    .card {
        background-color: transparent;
    }

    .row {
        display: block;
    }

    .noprint,
    #navbar,
    #topbar {
        display: none !important;
    }

    #maincontent {
        margin-top: 0rem !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    input[readonly] {
        background-color: transparent !important;
        border-color: transparent;
        padding: 0;
    }

    footer {
        position: relative;
        margin-top: 1rem;
    }

    footer > .container {
        max-width: 100% !important;
        line-height: 30px !important;
    }

    .kb-footer .d-print-block {
        line-height: initial;
    }

    body.booking-detail .kb-footer {
        height: 73px;
    }

    // Hide chat widget
    iframe[title="chat widget"] {
        display: none;
    }

}
